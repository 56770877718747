import React from 'react'
import "../style/allRight.css"
function AllRight() {
  return (
    <div>
        <p className='all__right'>Copyright © 2022 Vacantals | Powered by Vacantals</p>
    </div>
  )
}

export default AllRight